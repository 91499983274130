import React, { Component } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import logo from '../images/tdg-logo.svg'
//import React from "react"

//const Header = ({ siteTitle }) => (
class Header extends React.Component {
  // constructor to set state and bind "this"
  constructor(props) {
    super(props)
    this.state = { showModal: false }
    this.handleClick = this.handleClick.bind(this)
  }

  // function to handle the click
  handleClick() {
    this.setState(prevState => ({
      showModal: !prevState.showModal
    }))
  }
  render() {
    const menu = (
      <nav className="menu">
          <a href="https://doneger.com"><img src={logo} alt="doneger" style={{width: "20px",  margin: "1.45rem"}}/></a>
          <ul>
            <li><a href="https://doneger.com/about.html">About</a></li>
            <li><a href="https://doneger.com/services.html">Services</a></li>
            <li><a href="https://dngr.nyc">DNGR*</a></li>
            <li><a href="https://thinking.doneger.com">Thinking</a></li>
            <li><a href="tochttp://doneger.com/public/Media-Page.html">Media</a></li>
            <li><a href="https://doneger.com/public/Contact-Page.html">Contact</a></li>
          </ul>
      </nav>
    );

    return (
      <div>
        {this.state.showModal ? menu : ''} 

      <div
        id="top-banner"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '10px 30px',
          alignContent: 'center',
          zIndex: 99999,
          borderBottom: "1px solid rgb(242, 242, 242)"
        }}
      >

      
        <div className="login">
          <Link to="/"  style={{display: "flex", alignContent: "center"}}>
            <img src={logo} style={{width: "24px", margin: 0}}/>
            <h3 style={{ margin: "0 10px", lineHeight: "47px", fontWeight: 100}}>Thinking</h3>
          </Link>  
          <form style={{display:"none"}}
            action="https://doneger.com/authentication"
            method="post"
            id="login"
            name="login"
          >
            <input
              type="text"
              name="username"
              id="username"
              length="19"
              maxLength="30"
              placeholder="Username"
              autoComplete="off"
            />
            <input
              type="password"
              name="password"
              id="password"
              length="19"
              maxLength="30"
              placeholder="Password"
              autoComplete="off"
            />
            <input type="submit" value="Login" />
          </form>
        </div>

        


        <div id="menu-button">
          <div
            className="brgr"
            style={{ width: '33px', cursor: "pointer" }}
            onClick={this.handleClick}
          >
            <hr className="brgr-menu brgr-menu-top" />
            <hr className="brgr-menu brgr-menu-middle" />
            <hr className="brgr-menu brgr-menu-bottom" />
          </div>
        </div>
      </div>

      </div>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``
}

export default Header

/*
    <div id="main-logo-cap">
        <img src="images/doneger_logo.svg" id="small-logo"/>
        <p>We're inspired and driven by innovation.<br/>Read insights from our business and creative leadership.</p>
    </div>

    <div className="parallax" style={{backgroundImage:"url('http://thinking.doneger.com/images/thinking-hero.jpg')", backgroundSize: "cover", height: "605px"}}></div>



<header
    style={{
      background: `rebeccapurple`,
      marginBottom: `1.45rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <h1 style={{ margin: 0, textAlign: `center` }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
            
          }}
        >
          {siteTitle}
        </Link>
      </h1>
    </div>
  </header>
  */
