
import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Img from "gatsby-image"
import { Link } from 'gatsby'
import Helmet from 'react-helmet'


const BrowseBlogPosts = ({ data, pageContext }) => {
  const posts = data.allMdx.edges
  const { groupedPosts, group, page, numPages } = pageContext

  const isFirst = page === 1
  const isLast = page === numPages
  const prevPage = page - 1 === 1 ? '/' : (page - 1).toString()
  const nextPage = (page + 1).toString()
  
  return (
    <Layout>
       <Helmet>
          <title>Thinking</title>
          <link href="//cloud.typography.com/6710554/687028/css/fonts.css" rel="stylesheet" type="text/css"/>
          <link href="//cloud.webtype.com/css/c2f30112-2f02-42ee-8852-ff3a742a7c6f.css" rel="stylesheet" type="text/css" />
          <link rel="stylesheet" href="https://use.typekit.net/tkq8lmk.css"/>
        </Helmet>
          <div style={{height: "370px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", borderBottom: "1px solid rgb(242, 242, 242)", margin: "0 0 30px"}}>
              <p style={{fontFamily: "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif", fontSize: "44px", fontWeight: 500, lineHeight: "1.25em", margin: "0 auto", color: "#222", margin: "30px"}}>We're inspired and driven by innovation.<br/><span style={{color: "darkgrey"}}>Read insights from our business and creative leadership.</span></p>
          </div>

          <div
          style={{
            margin: `0 auto`,
            maxWidth: 1280,
            padding: `0px 1.0875rem 1.45rem`,
            paddingTop: 0
          }}
        >
          {posts.map(({ node: post }, index) => (
            <div style={{marginBottom: "30px", display: "flex", flexWrap: "wrap"}} key={post.id}>
                  <div className="teaserImage">
                      <Link to={post.fields.slug}>
                          <Img sizes={post.frontmatter.teaserImage.childImageSharp.sizes} />
                      </Link>
                    </div>
                  <div className="teaserInfo">
                    <div style={{background: "#fff",  padding: "20px 0 20px 0", padding: "25px 0 0 30px"}}>
                        <div className="news-text" style={{borderTop: "1px solid #000", padding: "20px 0 20px 0", borderBottom: "1px solid #000"}}>
                            <div className="news-header">{post.frontmatter.author} index:{index}</div>
                            <div className="pr-title"><Link to={post.fields.slug}>{post.frontmatter.title}</Link></div>
                            <time>{post.frontmatter.date}</time>
                        </div>
                        <div style={{textAlign: "right"}}>
                          <Link className="btn btn-read" to={post.fields.slug}>Read More</Link>
                        </div>
                    </div>    
                </div>
            </div>
          ))}

            <div className="articleNav">
            {!isFirst && (
                <Link className="articleNav articleNav__link articleNav__link--next" to={prevPage} rel="prev">
                Previous Page
                </Link>
            )}
            <div style={{display:'none'}}>
              Pages:{' '}
              {groupedPosts.map((x, index) => {
                const currentPage = index + 1
                const blogPath = index === 0 ? `/` : `/${currentPage}`
                return (
                  <Link
                    key={index}
                    to={blogPath}
                    className={currentPage === page ? 'active' : null}
                  >
                    {index + 1}
                  </Link>
                )
              })}
            </div>
            {!isLast && (
                <Link className="articleNav articleNav__link articleNav__link--prev"  to={nextPage} rel="next">
                Next Page
                </Link>
            )}

          </div>
        </div>  
    </Layout>
  )
}

export const query = graphql`
    query($skip: Int!, $limit: Int!) {
      allMdx(
        sort: { order: DESC, fields: [frontmatter___date] }
        limit: $limit
        skip: $skip
      ) {
      
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            author
            date(
              formatString: "MMMM D, YYYY"
            )
            teaserImage {
              childImageSharp {
                  sizes(maxWidth: 630) {
                      ...GatsbyImageSharpSizes
                  }
              }
            }
          }
        }
      }
    }
  }
`


export default BrowseBlogPosts

/*

              <div key={post.id}>
                <Img sizes={post.frontmatter.teaserImage.childImageSharp.sizes} />
                <h1>{post.frontmatter.title}</h1>
                <div>Posting By {post.frontmatter.author} | {post.frontmatter.date}</div>
                <Link to={post.fields.slug}>link</Link>
              </div>


              <img src="http://thinking.doneger.com/images/doneger_logo.svg" style={{width:"292px"}}/>

              backgroundImage:"url('http://thinking.doneger.com/images/thinking-hero.jpg')", backgroundSize: "cover", height: "507px",
              */